.template--offer .page__img {
    max-height: 20rem;
    width: 100%;
}

.template--offer .page__header {
    @extend .grad--cloud;
    @extend .shadow--bottom;
}

.offer-category .card {
    min-height: 8rem;
}

.offer-category .card__image {
    margin-bottom: 1rem;
}


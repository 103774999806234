// region GLOBALS
@import "normalize";
@import "fonts";

@import "mixins";
@import "global";
@import "utils";
// endregion

//region COMPONENTS
@import "grid";
@import "components";
@import "interactions";
@import "nav";
//endregion

// region SECTIONS
@import "masthead";
@import "sections";
@import "hero";
@import "footer";
// endregion


//region PARTS
@import "pages";
@import "blog";
@import "person";
@import "letswork";
@import "offers";
//endregion

.site-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.site-content {
    flex: 1;
}

.sidebar {
    @include largerThan(br(lg)) {
        padding-left: 2rem;
    }
}


.masthead--mounted .site-header {
    height: 3rem;
}

.page__header {
    @extend .grad--cloud;

    min-height: 40vh;

    .template--blog & {
        color: $indigo;
    }


    .template--category & {
        color: $blue--vivid;
        //color: white;
        //background-image: linear-gradient(30deg, #C2185B, #880E4F);
    }
}

.page__title {
    font-size: 2rem;
}

.page__id {
    width: 100%;

    @include largerThan(br(lg)) {
        padding-right: 2rem;
    }
}


.author {
}

//region 404 PAGE
.not-found {
    min-height: 70vh;
    text-align: center;
}

.not-found__image {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
}

//endregion

//region SITEMAP
.template--sitemap {
    ul ul {
        padding-left: 1rem;
    }
}

.sitemap-level {
    border-left: 0.2rem solid $blue--vivid;
}

//endregion


$footerBg: white;
$footerColor: #777;

.footer {
    color: $footerColor;
    position: relative;
}


.footer:before{
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 10rem;
    background-image: linear-gradient(to top, rgba($c-body, 0), rgba($c-body, 0.025) 70%, rgba($c-body, 0.1));
    z-index: -1;
}

.footer__logo {
    height: 2em;
    width: 2em;
    fill: currentColor;
    vertical-align: middle;
}


//region FOOTER NAVIGATION
.footer__nav-link {
    display: block;

    color: $footerColor;
    &:hover {
        color: $blue--vivid;
    }
}
//endregion

//region SOCIAL BUTTONS
.social__link {
    //display: inline-block;
    font-size: 1.5rem;
    padding: 0.25rem 0.5rem;
    vertical-align: middle;
    color: $footerColor;
}

@each $soc, $socColor in $socialColors {
    [data-social="#{$soc}"]:hover {
        color: $socColor;
    }
}

//endregion

//region COPYRIGHT
.footer__copyright {
    color: rgba($footerColor, 0.5);
    border-top: 1px solid;
}
//endregion
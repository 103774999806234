.rich {

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6 {
        margin: 1em 0 0.5em 0;
        clear: both;
    }

    p + p {
        margin-top: 1em;
    }

    img {
        max-width: 100%;
        display: block;
        margin: 2rem 0;
    }

    a {

    }

    ul {
        list-style: disc inside;
        margin: 1rem 0;
    }

    ul ul {
        padding-left: 1rem;
    }

    ol {
        margin: 1rem 0;
        list-style: decimal inside;
    }

    blockquote {
        font-size: 1.25rem;
        margin: 1rem 0;
        line-height: 1.25;
        //font-weight: bold;
        color: $blue--vivid;
        padding-left: 1rem;
        border-left: 0.2rem solid;

        @include largerThan(br(lg)) {
            font-size: 1.33em;
            padding-left: 2rem;
            //float: left;
            margin: 3rem 3rem 3rem -3rem;
            max-width: 80%;
        }

        @include largerThan(br(xl)) {
            margin-left: -6rem;
        }
    }

    table {
        width: 100%;
        margin: 1rem 0;
        text-align: left;
        font-size: 0.9em;
        border-collapse: collapse;
        border: 0;
    }
    table th {
        @extend %title--wide;
        border: 0;
        padding: 0.5rem 0.25rem;
        font-size: 0.75em;
        border-bottom: 2px solid $blue--vivid;
        color: $blue--vivid;
    }
    table td {
        padding: 0.5rem 0.25rem;
        color: #666;
    }

    table tr:hover {
        background-color: rgba($blue--vivid, 0.1);
    }

    *:nth-child(1) {
        margin-top: 0;
    }
}

.button {
    display: inline-block;
    padding: 0.5em 1.5em;
    background: white;
    border-radius: 0.25em;

    font-weight: bold;
    text-transform: uppercase;

    letter-spacing: 1px;
    border: 0.2rem solid;

    text-decoration: none;
    appearance: none;

    @extend .tr--short;
    &:hover {
        transform: translateY(-0.1rem) scale(1.025);
    }
}

.button.cta {
    border: 0;
    color: white;
    padding: 1rem 2rem;
    box-shadow: 0 0.25rem 0.5rem rgba($c-body, 0.2);
    @extend .grad--blue;
}

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
    vertical-align: -10%;

    &--large {
        width: 3rem;
        height: 3rem;
    }
}

.link {
    text-decoration: none;
    color: #333;
    color: currentColor;
    &:hover,
    &:focus {
        color: $blue--vivid;
    }

    &:active {
        color: darken($blue--vivid, 10%);
    }
}

[class*='image'] img {
    display: block;
}

.list__item + .list__item {
    margin-top: 1rem;
}

//region PAGINATION
.pagination {
    text-align: center;
    margin: 1rem auto;
}

.pagination__item {
    & + & {
        margin-left: 1rem;
    }

    display: none;
    @include largerThan(br(lg)) {
        display: inline-block;
    }
}

.pagination__current {
    background-color: $blue--vivid;
    padding: 1rem;
    color: white;
    display: inline-block;
}

.pagination__link {
    padding: 1rem;
    display: inline-block;
}

//endregion

//region YEARED ARCHIVE
.list-yeared td {
    vertical-align: top;
}

.list-yeared td + td {
    padding-left: 1rem;
}

.year__year {
    font-weight: bold;
    padding: 0 0 1rem 1rem;
    margin-bottom: 1rem;
    color: $blue--vivid;
    border-style: solid;
    border-width: 0 0 0.2rem 0.2rem;

    @include largerThan(br(md)) {
        margin-bottom: 0;
        padding: 1rem 1rem 0 0;
        border-width: 0.2rem 0.2rem 0 0;
    }
}

// endregion

//region BREADCRUMBS
.breadcrumbs {
    margin: 0.25rem;
}

.breadcrumb__link {
    color: currentColor;
    .breadcrumb:hover & {
        opacity: 1;
        color: currentColor;
    }
}

.breadcrumb {
    opacity: 0.75;

    &:before,
    &:after {
        opacity: 0.65;
    }

    &:hover:before,
    &:hover:after {
        opacity: 1;
    }

    &:hover {
        opacity: 1;
    }
}

.breadcrumb:last-of-type {
    &:after {
        content: '┒';
    }
}

.breadcrumb:not(:first-of-type) {
    &:before {
        content: '─';
    }
}

//endregion

//region SCROLLBARS
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: rgba($blue--vivid, 0.1);
}

::-webkit-scrollbar-thumb {
    background-color: $blue--vivid;
}

//endregion

//region CARD
.card {
    @extend %flex--column;
    width: 100%;
    min-height: 6rem;
    //padding: 1rem;
    position: relative;

    //border: 0.2rem solid $blue--vivid;
    box-shadow: 0 0.25rem 0.5rem rgba($c-body, 0.1);
    &:hover {
        //background-color: $blue--vivid;
        color: $blue--vivid;
        box-shadow: 0 0.25rem 1rem rgba($c-body, 0.25);
    }
}

.card__inner {

}

.card__top {
    line-height: 1.25;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 1rem;
    display: block;
}

.card__bottom {
    padding: 1rem;
    margin-top: auto;
    display: block;
}

.card__image {
    padding-bottom: 100% * (2 / 3);
    width: 100%;

    .card--half & {
        padding-bottom: 50%;
    }
}

.card-list .list__item {
    margin: 0;
    display: flex;
    transform: scale(0.95);
    @extend %transition;

    &:hover {
        transform: scale(1);
        box-shadow: 0 0 1rem rgba($blue--dark, 0.1);
    }
}

//endregion

//region SWITCHER
.switcher {
    display: block;
    position: relative;
    overflow: hidden;
}

.switcher__option {
    @extend %transition;
    display: block;
    .switcher:hover & {
        transform: translateY(-100%);
    }
}

.switcher .switcher__option:last-of-type {
    position: absolute;
}

//endregion

// region INPUTS

input, textarea {
    width: 100%;
    padding: 0.5rem 0;
    border: 0.15em solid transparent;
    border-bottom: 0.15em solid #CCC;

    &:focus {
        border-bottom-color: $blue--vivid;
        outline: 0;
    }
}

// endregion

//region POPUP

.popup {
    color: white;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 5;
    width: 80%;
    max-width: 18rem;
}

.popup__field {
    padding: 0.5rem;
    color: $purple;
}

.popup__close {
    line-height: 1;
    position: absolute;
    top: 0.75em;
    right: 1em;
}

.popup__thanks {
    @extend .grad--sunset;
    color: $purple;
}

.popup__form {
    z-index: 20;
    @extend .grad--purple;
}

.popup__thanks-icon {
    width: 4rem;
    height: 4rem;
    display: block;

}

//endregion

//region FLIPPER
.flipper-wrapper {
    perspective: 2000px;
    transform: translateY(4rem) scale(0.9);
    opacity: 0;
    @extend .tr--short;
    .slide-in & {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

.flipper {
    @extend .tr--short;
    transform-style: preserve-3d;

    will-change: transform;

    .flip & {
        transform: rotateX(180deg);
    }
}

.flipper__back,
.flipper__front {
    padding: 1rem;
}

.flipper__back {
    transform: rotateX(180deg);
}

.flipper__front {
    z-index: 11;
    transform: rotateX(0deg);
}

//endregion

//region KUDOS
.kudos-container {
    .no-js & {
        display: none;
    }
}

.kudos {
    max-width: 15rem;
    margin-right: auto;
}

.kudos__icon {
    width: 3.75rem;
    height: 3.75rem;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    border: 0.2rem solid;
    border-radius: 4rem;
    transition: 0.4s;

    .active:not(.complete) & {
        color: $indigo;
    }
    .complete & {
        color: $indigo;
    }
}

.kudos__icon svg {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 50%;
    left: 50%;
    margin-left: -1rem;
    margin-top: -0.8rem;
    vertical-align: middle;
    display: block;
    will-change: transform;
    transition: transform 1s;

    .active:not(.complete) & {
        transform: scale(1.2);
    }

    .complete & {
        animation: grow-ping 0.75s;
    }
}

.kudos__text {
    margin-left: 0.5rem;
    display: inline-block;
    vertical-align: middle;
}

.kudos__status {
    display: block;
    .active:not(.complete) & {
        display: none;
    }
}

.kudos__directive {
    display: none;
    .active:not(.complete) & {
        display: block;
    }
}

//endregion


.promote {
    border-bottom-width: 3px;

    padding: 2rem;
    margin: 2rem auto;

    box-shadow: 0 0.25rem 1rem rgba($c-body, 0.2);

    &:hover {
        border-color: $indigo;
    }
    &:hover a {
        color: $indigo;
    }
}
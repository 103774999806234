.hero {
    position: relative;
    min-height: 100vh;

    /* Prevent jumps on mobile devices when top bar hides */
    @extend %prevent-vh-jump;
}

.hero__image {
    opacity: 0.33;

    background-size: 150% auto;
    background-position: bottom center;

    @include largerThan(40rem) {
        background-size: cover;
    }
}

.hero__logo {
    max-width: 15rem;
    margin-left: auto;
    margin-right: auto;
}

.hero__content {
    font-size: 1.25rem;
}

.masthead {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 15;
    background-color: white;
    //box-shadow: 0 0.25rem 0.5rem rgba(#444, 0.1);
    border-bottom: 1px solid $c-dimmed;

    transform: translateZ(0);
    will-change: transform;

    transition: transform 0.3s;

    justify-content: space-between;
    box-shadow: 0 0.25rem 1rem rgba($c-body, 0.1);

    .scrolling-down & {
        transform: translateY(-4rem);
    }
}

.masthead__logo {
    font-size: 2rem;
    fill: $blue--vivid;
}

.masthead [href="/ozel-ders-al"] {
    color: #D81B60;
}
body.nav-open {
    overflow-y: hidden;
}

.nav-wrapper {
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    top: 0;
    right: 100%;
    width: 18rem;

    height: 100vh;
    background-color: white;
    padding: 1rem;

    z-index: 15;
    transition: 0.3s;
    will-change: transform;


    .nav-open & {
        transform: translate3d(100%, 0, 0);
    }

    @include smallerThan(br(md)) {
        box-shadow: 0 0.25rem 2rem rgba($c-body, 0.33);
        transform: translate3d(-2rem, 0, 0);
    }

    @include largerThan(br(lg)) {
        position: static;
        top: 0;
        left: 0;

        overflow: visible;
        height: auto;
        width: auto;
        padding: 0;

        border-right: 0;
    }

    @extend .no-vh-jump;
}

.nav__item {
    a {
        display: block;
        padding: 1rem;
    }
    &--first {
        @include largerThan(br(lg)) {
            position: static;
            display: inline-block;
        }
    }

    @extend .title--small;
}

.nav__item--open {
    position: relative;
    background-color: rgba($blue--vivid, 0.1);
}

.submenu {
    border-left: 0.2rem solid $blue--vivid;

    margin-bottom: 1rem;
    display: none;

    .nav__item--open & {
        display: block;
    }


    @include largerThan(br(md)) {
        position: absolute;
        top: 100%;
        background-color: white;
        width: 15rem;
        border: solid $blue--vivid;
        border-width: 0 1px 1px 0.2rem;

        //box-shadow: 0 0.5rem 2rem rgba($c-body, 0.33);
    }

}

.nav__item .link {
    color: currentColor;
}

.nav__item .extra {
    @include largerThan(br(md)) {
        position: absolute;
        width: 1px;
        height: 1px;
        overflow: hidden;
        color: #fff;
    }
    @include largerThan(br(xl)) {
        position: static;
        width: auto;
        height: auto;
        overflow: auto;
        color: currentColor;
        display: inline;
    }
}

.submenu .nav__item {
    width: 100%;
    display: block;
    &:hover {
        background-color: $blue--vivid;
        color: white;
    }

    @include largerThan(br(md)) {
    }
}

.nav-trigger {
    font-size: 1.5rem;
    padding: 0.5rem;
    margin-left: 1rem;
}

.overlay {
    position: absolute;
    z-index: 9;
    //background-color: $c-body;
    //opacity: 0;
    pointer-events: none;

    transition: opacity 0.5s;
    will-change: opacity;
    &.show {
        //opacity: 0.25;
        pointer-events: auto;
        //transition: opacity 1s;
    }
}
//region TEXT
.t--mini {
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: bold;
}

.title--small {
    @extend .t--mini;
    letter-spacing: 1px;
}

.t--small {
    font-size: 0.8rem;
}

.t--bold {
    font-weight: bold;
}

.t--right {
    text-align: right;
}

.t--center {
    text-align: center;
}

.lead {
    font-size: 1.25em;

    @include largerThan(br(md)) {
        font-size: 1.33em;
    }
    @include largerThan(br(lg)) {
        font-size: 1.5em;
    }
}

.t--upper {
    text-transform: uppercase;
}

.t--zip {
    line-height: 1.1;
}

//endregion

//region POSITIONING
.push {
    margin-bottom: 1rem;
}

.push--more {
    margin-bottom: 2rem;
}

.push--moree {
    margin-bottom: 4rem;
}

.push--less {
    margin-bottom: 0.5rem;
}

.pad-v {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.pad-h {
    padding-left: 1rem;
    padding-right: 1rem;
}

.pad-v--more {
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.pad-v--less {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.pad-v--moree {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.pad-v--moreee {
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.mid {
    vertical-align: middle;
}

.fill {
    @extend %fill-parent;
}

.list--inline li {
    display: inline-block;
}

//endregion

.img--responsive {
    width: 100%;
    height: auto;
}

.reset {
    @extend %reset--all;
}

.no-interaction {
    pointer-events: none;
}

.no-selection {
    user-select: none;
}

.dimmed {
    opacity: 0.5;
}

.bg {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: -1;
}

.invisible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.hidden {
    display: none;
}

.tr--short {
    @extend %transition;
}

.tr--long {
    transition: 0.5s ease-out;
}

.pointer {
    cursor: pointer;
}

.focus:hover {
    opacity: 1;
}

.no-vh-jump {
    transition: min-height 300s steps(1);
}

.block {
    display: block;
}

.grad--orange {
    background-image: linear-gradient(120deg, #FF9A9E 0%, #FAD0C4 99%, #FAD0C4 100%);
}

.grad--purple {
    background-image: linear-gradient(120deg, #667EEA 0%, #764BA2 100%);
}

.grad--peach {
    background-image: linear-gradient(120deg, #F78CA0 0%, #F9748F 19%, #FD868C 60%, #FE9A8B 100%);
}

.grad--indigo {
    background-image: linear-gradient(135deg, #EC407A, #E91E63, #AD1457);
}

.grad--blue {
    background-image: linear-gradient(120deg, #2196F3, #1976D2);
}

.grad--sunset {
    background-image: linear-gradient(120deg, #E0C3FC 0%, #8EC5FC 100%);
}

.grad--cloud {
    background-image: linear-gradient(120deg, #FDFBFB 0%, #EFF1F2 100%);
}

.b--top {
    border-top: 1px solid $c-dimmed;
}

.b--bottom {
    border-bottom: 1px solid $c-dimmed;
}

.shadow--bottom {
    position: relative;
    &:after {
        z-index: -1;
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        top: 100%;
        height: 10rem;
        background-image: linear-gradient(to top, rgba($c-body, 0), rgba($c-body, 0.04) 70%, rgba($c-body, 0.10));
    }
}

.shadow--top {
    position: relative;
    &:before {
        z-index: -1;
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 100%;
        height: 10rem;
        background-image: linear-gradient(to bottom, rgba($c-body, 0), rgba($c-body, 0.04) 70%, rgba($c-body, 0.10));
    }
}
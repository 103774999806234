$blue--vivid: #396BF7;
$blue--dark: #1B3775;

$red--vivid: #E84C30;
$indigo: #E91E63;
$green--vivid: #09AA3E;
$purple: #673AB7;

$c-body: #333;
$c-link: $c-body;
$c-link--hover: $blue--vivid;
$c-dimmed: #CCC;

$bg-body: white;

$socialColors: (
        instagram: #3F729B,
        facebook: #3B5998,
        twitter: #55ACEE,
        youtube: #CD201F,
        linkedin: #0077B5,
        vine: #00B488,
        googleplus: #DC4E41,
        whatsapp: #25D366
);

$br: (
        xs: 20rem,
        sm: 25rem,
        md: 40rem,
        lg: 55rem,
        xl: 70rem
);

@function br($size) {
    @return map_get($br, $size);
}

@function at($screenSize) {
    //@return {}
}

@mixin largerThan($width) {
    @media all and (min-width: $width) {
        @content;
    }
}

@mixin smallerThan($width) {
    @media all and (max-width: $width) {
        @content;
    }
}

%reset--all {
    margin: 0;
    padding: 0;
    appearance: none;
    background: 0;
    border: 0;
    list-style: none;
}

%reset {
    margin: 0;
    padding: 0;
}

%fill-parent {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

%transition {
    transition: 0.2s ease-out;
}

%transition--long {
    transition: 0.5s ease-out;
}

%title--wide {
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: bold;
}

%link--vivid {
    text-decoration: none;
    color: $blue--vivid;
    &:hover,
    &:focus {
        color: lighten($blue--vivid, 10%);
    }
    &:active {
        color: darken($blue--vivid, 10%);
    }
}

%flex--vertical {
    flex-direction: column;
    justify-content: space-between;
}

%flex {
    display: flex;
}

%flex--column {
    flex-direction: column;
}

%flex--center {
    display: flex;
    align-items: center;
    justify-content: center;
}

%prevent-vh-jump {
    transition: 300s steps(1);
}

%margin-between {
    & + & {
        margin-top: 1rem;
    }
}

%img--responsive {
    width: 100%;
    height: auto;
}


%shadow {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    z-index: -1;
    height: 10rem;
}
$fontsBaseUrl: '../fonts';


@font-face {
    font-family: 'Sofia Pro';
    src: url('#{$fontsBaseUrl}/sofia-pro/SofiaProRegular.eot');
    src: url('#{$fontsBaseUrl}/sofia-pro/SofiaProRegular.eot?#iefix') format('embedded-opentype'),
    url('#{$fontsBaseUrl}/sofia-pro/SofiaProRegular.woff2') format('woff2'),
    url('#{$fontsBaseUrl}/sofia-pro/SofiaProRegular.woff') format('woff'),
    url('#{$fontsBaseUrl}/sofia-pro/SofiaProRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('#{$fontsBaseUrl}/sofia-pro/SofiaPro-BoldItalic.eot');
    src: url('#{$fontsBaseUrl}/sofia-pro/SofiaPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('#{$fontsBaseUrl}/sofia-pro/SofiaPro-BoldItalic.woff2') format('woff2'),
    url('#{$fontsBaseUrl}/sofia-pro/SofiaPro-BoldItalic.woff') format('woff'),
    url('#{$fontsBaseUrl}/sofia-pro/SofiaPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('#{$fontsBaseUrl}/sofia-pro/SofiaPro-Bold.eot');
    src: url('#{$fontsBaseUrl}/sofia-pro/SofiaPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$fontsBaseUrl}/sofia-pro/SofiaPro-Bold.woff2') format('woff2'),
    url('#{$fontsBaseUrl}/sofia-pro/SofiaPro-Bold.woff') format('woff'),
    url('#{$fontsBaseUrl}/sofia-pro/SofiaPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('#{$fontsBaseUrl}/sofia-pro/SofiaPro-Italic.eot');
    src: url('#{$fontsBaseUrl}/sofia-pro/SofiaPro-Italic.eot?#iefix') format('embedded-opentype'),
    url('#{$fontsBaseUrl}/sofia-pro/SofiaPro-Italic.woff2') format('woff2'),
    url('#{$fontsBaseUrl}/sofia-pro/SofiaPro-Italic.woff') format('woff'),
    url('#{$fontsBaseUrl}/sofia-pro/SofiaPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
*,
*:before,
*:after {
    box-sizing: border-box;
}

body {
    font-size: 16px;
    line-height: 1.5;
    color: #262626;
    font-family: "Sofia Pro", "Proxima Nova", sans-serif;
    font-weight: 400;
    overflow-x: hidden;

    @include largerThan(br(lg)) {
        font-size: 18px;
    }
}

ul, ol {
    @extend %reset;
    list-style: none;
}


blockquote {
    margin: 0;

    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

p {
    @extend %reset;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    @extend %reset;
    line-height: 1.1;
    font-weight: bold;
}

h1, .h1 {
    font-size: 2em;
}

h2, .h2 {
    font-size: 1.6em;
}

h3, .h3 {
    font-size: 1.4em;
}

h4, .h4 {
    font-size: 1.2em;
}

h5, .h5 {
    font-size: 1.1em;
}

h6, .h6 {
    font-size: 1em;
}

a {
    text-decoration: none;
    color: $blue--vivid;

    &:hover,
    &:focus {
        color: currentColor;
    }

    &:active {
        color: currentColor;
    }
}

table td {
    @include smallerThan(br(sm)) {
        display: block;
    }
}

.section--lets-work {
    color: $indigo;
    border-top: 1px solid rgba($indigo, 0.2);
    border-bottom: 1px solid rgba($indigo, 0.2);
    margin-top: 4rem;
}

// shadow
.section--lets-work:before {
    @extend %shadow;
    bottom: 100%;
    background-image: linear-gradient(to bottom, rgba($c-body, 0), rgba($c-body, 0.025) 70%, rgba($c-body, 0.1));
}

.section--lets-work .cta.button {
    @extend .grad--indigo;
}

.lets-work__image {
    opacity: 0.25;
}


// lets work page option
.lets-work__section {
    transform: scale(0.95);
    &:hover {
        color: $blue--vivid;
        transform: scale(1);
    }
}


.lets-work__section-image {
    max-width: 16rem;
    max-height: 16rem;
    margin-left: auto;
    margin-right: auto;
}

#form {
    display: none;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;

    &:target,
    .show-form & {
        display: block;
    }
}



.form__errors {
    background-color: rgba($red--vivid, 0.1);
    margin: 1rem 0;
    list-style-position: inside;
}

.field {
    width: 100%;
}

.form__error {
    color: $red--vivid;
}

.form__field-title {
    display: block;
}

.form__complete {
    color: $blue--vivid;
}

.form__complete-img {
    max-width: 20rem;
    margin: auto;
}

.course-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: -4px;

    justify-content: center;
}

.course-list__item {
    margin: 4px;
}

.course__checkbox {
    display: none;
}

.course__label {
    border: 0.2rem solid transparent;
    width: 100%;
}

.course__checkbox:checked ~ .course__label {
    border: 0.2rem solid $blue--vivid;
}

.lets-work__form-cta:hover,
.lets-work__form-cta:focus,
.lets-work__form-cta:active {
    color: $blue--vivid;
}

[data-anim] {
    transition: transform 0.33s, opacity 0.2s;
}

.grow {
    opacity: 0.75;
    transform: scale(0.9);
}

.rise {
    opacity: 0.75;
    transform: translateY(2rem);
}

.slide {
    opacity: 0;
    transform: translateX(-2rem);
}

.rotate {
    //transform: rotatez();
}

@keyframes pulse {
    50% {
        transform: scale(1.05);
    }
}

@keyframes grow-ping {
    0% {
        transform: scale(1.2);
    }
    50% {
        transform: scale(1.2);
    }
    75% {
        transform: scale(1.3);
    }
}

@keyframes grow {
    50% {
        transform: scale(1.05);
    }
}

@keyframes flip-vanish {
    80% {
        transform: rotateX(180deg);
        opacity: 1;
    }
    100% {
        transform: rotateX(180deg) scale(0.9);
        opacity: 0;
    }
}

@keyframes ping {
    50% {
        opacity: 0.5;
    }
}

@keyframes scale-fade {
    100% {
        opacity: 0;
        transform: scale(0.8);
    }

}

.scale-fade {
    animation: scale-fade 0.2s 2s both;
}

.vanish {
    animation: scale-fade 0.2s both;
}

.ping {
    animation: ping 0.4s ease both;
}

.fade-in-out {
    animation: ping 1s ease both infinite;
}
.grid {
    display: flex;
    flex-flow: row wrap;
}

[class*='block-grid'] {
    @extend .grid;
}

[class*='block-grid'] .grid-u {
    flex: 100%;
}

.block-grid-2 .grid-u {
    flex: 0 0 50%;
    max-width: 50%;
}

.block-grid-2--md .grid-u {
    @include largerThan(br(md)) {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.block-grid-3--md .grid-u {
    @include largerThan(br(md)) {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
    }
}

.block-grid-2--lg .grid-u {
    @include largerThan(br(lg)) {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.block-grid-3--lg .grid-u {
    @include largerThan(br(lg)) {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
    }
}

.block-grid-4--lg .grid-u {
    @include largerThan(br(lg)) {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

.block-grid-4--xl .grid-u {
    @include largerThan(br(xl)) {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

[class^='grid-u'] {
    flex: 100%;
}

.grid-u--sm {
    flex: 1;
}

.grid-u-2--sm {
    flex: 2;
}

.grid-u-3--sm {
    flex: 3;
}

@media screen and (min-width: br(md)) {
    .grid-u--md {
        flex: 1;
    }

    .grid-u-2--md {
        flex: 2;
    }

    .grid-u-3--md {
        flex: 3;
    }
}

@media screen and (min-width: br(lg)) {

    .grid-u--lg {
        flex: 1;
    }

    .grid-u-2--lg {
        flex: 2;
    }

    .grid-u-3--lg {
        flex: 3;
    }

    .grid-u-4--lg {
        flex: 4;
    }

}

.flex {
    display: flex;
}

.center--v {
    @extend %flex--center;
    @extend %flex--column;
}

.center--h {
    @extend %flex--center;
}


.p--clamp {
    padding-left: 1rem;
    padding-right: 1rem;

    @include largerThan(br(md)) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    @include largerThan(br(lg)) {
        padding-left: 10%;
        padding-right: 10%;
    }
}

.p--readable {
    padding-left: 1rem;
    padding-right: 1rem;

    @include largerThan(br(lg)) {
        padding-left: 20%;
        padding-right: 20%;
    }
}

.w--readable {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
}

.hide--md {
    @include largerThan(br(md)) {
        display: none;
    }
}
.hide--lg {
    @include largerThan(br(lg)) {
        display: none;
    }
}

.container {
    position: relative;
}


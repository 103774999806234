//region POST FULL
.template--post .page__header {
    min-height: 80vh;
    background-color: $blue--vivid;
    @extend .grad--blue;
    color: white;

    @include largerThan(br(lg)) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}

.post__id {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include largerThan(br(lg)) {
        padding-right: 2rem;
    }
}

.post__summary {
    @include largerThan(br(lg)) {
        padding-top: 1rem;
        border-top: 1px solid rgba(white, 0.5);
    }
}

.post__body {
    @include largerThan(br(lg)) {
        max-width: 750px;
        margin-right: auto;
    }

    & > p:first-of-type:first-letter {
        float: left;
        font-size: 4em;
        color: $blue--vivid;
        font-weight: bold;
        line-height: 1;
        margin-right: 1rem;
    }
}

.post__aside {
    width: 100%;
}

.post__footer {
    @include largerThan(br(lg)) {
        max-width: 66.66%;
    }
}

.post__share-buttons {
    font-size: 1.5rem;
}

.template--post .page__image {
    border-radius: 0.25rem;
    box-shadow: 0 0.25rem 2rem rgba($c-body, 0.25);
    overflow: hidden;
}

.post__kudos {
    //text-align: right;
}

//endregion

//region POST SUGGESTION

.post__related {
    margin: 1rem 0;

    @include largerThan(br(lg)) {
        float: left;
        margin-right: 1rem;
        width: 40%;
    }
}

.post__suggestion .card__image,
.post__related .card__image {
    height: 0;
    padding-bottom: 25%;
}
.post__related .card__image {
    @include largerThan(br(lg)) {
        padding-bottom: 50%;
    }
}


//endregion

//region BLOG PAGE
.template--blog .sidebar {
    @include largerThan(br(lg)) {
        padding-left: 2rem;
    }
}

.blog-list .list__item {
    //min-height: 10rem;
    //@include largerThan(br(lg)) {
    //    min-height: 16rem;
    //}
}

.post-card__category {
    .post-card:hover & {
        opacity: 1;
    }
}

.post-card__title {
    word-wrap: break-word;
}

//endregion

.facebook__image {
    $fb: map_get($socialColors, facebook);
    background-color: lighten($fb, 50%);
    color: $fb;
    z-index: 5;

    @extend .tr--short;
    [fb-xfbml-state="rendered"] ~ & {
        transition-delay: 2s;
        opacity: 0;
        @extend .no-interaction;
    }
}

.facebook__img {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 50%;
    left: 50%;
    margin-left: -1rem;
    margin-top: -1rem;

}
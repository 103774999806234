//region SECTION
.section {
    position: relative;
    padding-top: 4rem;
    padding-bottom: 4rem;
    min-height: 75vh;
    transition: transform 0.2s;

    @include largerThan(br(lg)) {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
}

.section__title {
    @include largerThan(50rem) {
        font-size: 2.5rem;
    }
}

.section__image {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
}

.section__image--wide {
    @extend .section__image;
    max-width: 45rem;
}

.section__action {
    margin-top: 1rem;
}

.section {
    @extend .shadow--top;
}

//endregion

//region HOW WE WORK
.step {
    display: flex;
    justify-content: center;
    position: relative;
}

.step__title {
    @include largerThan(br(lg)) {
        text-align: left;
    }
}

.step__img {
    max-width: 8rem;
    max-height: 8rem;
    padding: 1rem;

    @extend %img--responsive;
}

//endregion

//region WHY US
.reason {
    @extend .step;
}

.reason-list .list__item + .list__item {
    margin-top: 2rem;
}

.reason__title {
    @extend .step__title;
}

.reason__img {
    @extend .step__img;
}

//endregion

//region TESTIMONIALS
.testimonial {
    position: relative;
}

.testimonial__cite {
    font-style: normal;
}

.testimonial__icon {
    position: absolute;
    z-index: -1;
    opacity: 0.15;
    fill: $blue--vivid;
    width: 6rem;
    height: 6rem;
    left: 0;
    top: 0;
}

.initial {
    display: inline-block;
    background: #666;
    border-radius: 2rem;
    text-align: center;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    position: relative;

    & + & {
        margin-left: 0.5rem;
    }
    transform: scale(0.9);
}

.initial:first-of-type,
.initial.active {
    background-color: $blue--vivid;
    transform: scale(1.1);
}

//endregion

//region STATS
.stat-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}


.stat-list .list__item {
    @extend %transition;
    margin: 0;
    flex: 1 0 50%;
    text-align: center;
    padding: 1rem 0.5rem;
    transform: scale(0.95);

    &:hover {
        transform: scale(1);
    }
}

.stat__amount {
    font-size: 2.5rem;
    line-height: 1;
    @include largerThan(br(lg)) {
        font-size: 3.5rem;
    }
}

.js-count.done {
    transition: color 0.5s;
    color: #D81B60;
    animation: pulse 1s both;
}


//endregionS


.person__title {
    margin: 0 0 1rem;
    line-height: 1.1;
}


.person__image {
    max-width: 25rem;
    margin-right: auto;
    margin-left: auto;
}
